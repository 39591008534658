<template>
  <div class="ranklist">
    <template v-for="(item, index) in playlist" :key="index">
      <div
        v-if="(isSizeLimit && index < isSizeLimit) || !isSizeLimit"
        class="ranklist-item"
        @click="handleClickRank(item)"
      >
        <img class="ranklist-item-cover" :src="item.image" v-img-fallback="'https://qncweb.ktvsky.com/20211216/vadd/c6d01bc5aa27699dc276fbeab70ecfe5.png'" />
        <p>{{ item.name }}</p>
      </div>
    </template>
    
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { getPlaylist } from '@/service/playlist'
import { useRouter } from 'vue-router'
import { sendLog } from '@/directives/v-log/log'

export default {
  name: 'RankList',
  props: {
    isSizeLimit: {
      type: Number,
      default: 0
    }
  },
  emits: ['click-rank'],
  setup(props, { emit }) {
    const playlist = ref([])
    const router = useRouter()
    const getRankList = async() => {
      playlist.value = await getPlaylist() 
    }

    const handleClickRank = ({name, image, id}) => {
      // 歌单上报
      sendLog({
        event_type: 'custom',
        event_name: 123,
        event_data: {
          song_list: name,
          song_list_id: name
        }
      })
      emit('click-rank', { name, id})
      router.push({
        name: 'playList',
        query: {
          name,
          image,
        },
      })
    }

    onMounted(() => {
      getRankList()
    })

    return {
      playlist,
      handleClickRank,
      getRankList
    }
  }
}
</script>

<style lang="stylus" scoped>
.ranklist
  margin 0 auto
  text-align center
  display grid
  grid-template-columns repeat(6, 240px)
  justify-content space-between
  &-item
    width 240px
    margin-bottom 60px
    color #B4B4B4
    font-size 28px
    &-cover
      width 100%
      height 240px
      border-radius 20px
      margin-bottom 30px
    p
      text-align left
  @media screen and (max-width 1200px)
    grid-template-columns repeat(4, 230px)
    &-item
      width 230px
      &-cover
        height 230px
</style>
