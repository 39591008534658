<template>
  <div class="song-sheet-page page">
    <HeadBar class="headbar" title="精品歌单"></HeadBar>
    <RankList class="song-sheet-page-list" />
  </div>
</template>

<script>
import RankList from '@/components/rank-list/index.vue'

export default {
  name: 'SongSheet',
  components: {
    RankList,
  },
  // setup(props) {
    
  //   return {

  //   }
  // }
}
</script>

<style lang="stylus" scoped>
.song-sheet-page
  width 100%
  height 100%
  padding-top 35px
  position relative
  color #000
  font-weight 400
  &-list
    margin-top 65px
</style>